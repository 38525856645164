<template>
  <div class="reports" v-if="hasPerm('reports')">
    <page-header title="Rapports" icon="fas fa-folder-open"></page-header>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-youth-home', }">
                <i class="fas fa-chart-bar"></i> Déclaration CAF - Par types de séances
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-youth-home-detail', }">
                <i class="fas fa-chart-bar"></i> Déclaration CAF - Paramétrage personnalisé
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-city-report', }">
                <i class="fas fa-chart-bar"></i> Répartition CAF par communes
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-handicap-report', }">
                <i class="fas fa-table-cells"></i> Liste des inscriptions AEEH
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'caf-configuration', }">
                <i class="fas fa-sliders"></i> Modèles CAF
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-youth-report', }">
                <i class="fas fa-chart-bar"></i> Déclaration Conseil Départemental
              </router-link>
            </li>
            <li v-if="hasAllPerms(['youth.view_seance', 'documents.view_taxyearlycertificaterule'])">
              <router-link :to="{ name: 'yearly-tax-certificates', }">
                <i class="fas fa-money-bill-alt"></i> Attestation fiscale de frais de garde
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'reports-home',
  mixins: [BackendMixin],
  components: {
    PageHeader,
  },
}
</script>

<style lang="less">
</style>
